/* eslint-disable */
import React, { lazy, Suspense, useState } from 'react';
import classNames from 'classnames';
import { Route, Switch } from 'react-router-dom';
import AppTopbar from '../layout/AppTopbar';
import AppFooter from '../layout/AppFooter';
import AppMenu from '../layout/AppMenu';
import AppInlineProfile from '../layout/AppInlineProfile';
import { EmptyPage } from '../common/EmptyPage';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import UserService from '../../api/UserService';
import SeasonList from '../Seasons/Season_list';
import SeasonDetail from '../Seasons/SeasonDetail';

import BatchList from '../Batch/Batch_list';
import BatchDetail from '../Batch/BatchDetail';
import MultiBatch from '../Batch/MultiBatch';
import BatchActionViewComponent from '../Batch/BatchList_ActionView';
import ReviewPreview from '../Reviews/Review_preview';
import ParticipantEdit from '../Bookings/ParticipantEdit';
import Unauthorized from '../UnAuthorized/Unauthorized';
import ParticipantView from '../Bookings/Participant_view';
import WebsiteContentMapping from '../WebsiteManagement/WebsiteContentMapping';
import FitnessApproval from '../Bookings/Fitness-Approval/FitnessApproval';
import ReviewDashboard from '../ReviewDashboard/ReviewDashboard';
import BatchCancellation from '../Batch/BatchCancellation';
import SpecialTrekTypeList from '../Trek/SpecialTrekTypeList';
import SpecialTrekTypeDetail from '../Trek/SpecialTrekTypeDetail';
import VoucherCode from '../Bookings/Booking-vouchers/VoucherCode/VoucherCode';

const Dashboard = lazy(() => import('../Dashboard/Dashboard'));
const LocationList = lazy(() => import('../Location/Location_list'));
const TagsList = lazy(() => import('../Miscellaneous/Tags/Tags_list'));
const AwardsList = lazy(() => import('../Miscellaneous/Awards/Awards_list'));
const BookingParticipants = lazy(() => import('../Bookings/Booking-Participants/Booking_Participants'));
const UserList = lazy(() => import('../User/UserList'));
const UserDetail = lazy(() => import('../User/UserDetail'));
const UserFlags = lazy(() => import('../User/UserFlags/FlagList'));
const BookingVochers = lazy(() => import('../Bookings/Booking-vouchers/Booking_Vouchers'));
const BackPackOffloading = lazy(() => import('../Bookings/BackPack-Offloading/BackPack_Offloading'));
const BackPackOffloadingDetails = lazy(() => import('../Bookings/BackPack-Offloading/BackPack_Offloadings_details'));
const ParticpantsVitalsComponent = lazy(() => import('../Bookings/Participants-Vitals/Partcipants_Vitals'));
const NewVitalComponent = lazy(() => import('../Bookings/Participants-Vitals/New-Vital/New_Vital'));
const NewVoucher = lazy(() => import('../Bookings/Booking-vouchers/New-Voucher/New_Voucher'));
const BulkVoucherComponent = lazy(() => import('../Bookings/Booking-vouchers/Bulk-Voucher/Bulk_Vocher'));
const VoucherTypesLists = lazy(() => import('../Miscellaneous/VoucherTypes/VoucherTypes_Lists'));
const PromotersList = lazy(() => import('../Miscellaneous/Promoters/Promoters_lists'));
const PromoterDetail = lazy(() => import('../Miscellaneous/Promoters/PromoterDetail'));
const TemplatesList = lazy(() => import('../Miscellaneous/Templates/Templates_lists'));
const LocationDetail = lazy(() => import('../Location/Location_detail'));
const TrekList = lazy(() => import('../Trek/Trek_list'));
const TrekDetail = lazy(() => import('../Trek/Trek_detail'));

const ReviewList = lazy(() => import('../Reviews/Review_list'));
const ReviewDetail = lazy(() => import('../Reviews/Review_detail'));

const BookingList = lazy(() => import('../Bookings/Booking_list'));
const BookingDetail = lazy(() => import('../Bookings/Booking_Detail'));
const ParticipantList = lazy(() => import('../Bookings/Participant_list'));
const ParticipantDetail = lazy(() => import('../Bookings/Participant_detail'));
const Transactions = lazy(() => import('../Bookings/Transactions'));
const RefundDetail = lazy(() => import('../Bookings/Refund_detail'));
const TransferDetail = lazy(() => import('../Bookings/Transfer'));
const BatchTransferDetail = lazy(() => import('../Batch/Transfer'));
const ParticipantCancellation = lazy(() => import('../Bookings/ParticipantCancellation'));
const ApproveParticipants = lazy(() => import('../Batch/ApproveParticipants'));
const BackpackParticipants = lazy(() => import('../Batch/BackpackParticipants'));
const BatchReviews = lazy(() => import('../Batch/BatchReviews'));
const ReviewComments = lazy(() => import('../Batch/ReviewComments'));

//const SeasonList = lazy(() => import('../Seasons/SeasonList'));

const App = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [menuMode, setMenuMode] = useState('horizontal');
  const [darkMenu, setDarkMenu] = useState(true);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [topbarMenuActive, setTopbarMenuActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [activeTopbarItem, setActiveTopbarItem] = useState(null);
  const [inlineMenuActive, setInlineMenuActive] = useState(false);
  const [profileMode, setProfileMode] = useState('popup');
  const [configActive, setConfigActive] = useState(false);
  const [inputStyle, setInputStyle] = useState('outlined');
  const [ripple, setRipple] = useState(false);

  let menuClick = false;
  let configClick = false;
  let topbarItemClick = false;
  let inlineMenuClick = false;

  const menu = [
    UserService.hasBackofficeRole() && {
      label: 'Home Page',
      icon: 'pi pi-fw pi-home',
      items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard' }],
    },
    // {
    //   label: 'User Dashboard',
    //   icon: 'pi pi-fw pi-home',
    //   items: [{ label: 'UserDashboard', icon: 'pi pi-fw pi-home', to: '/user-dashboard' }],
    // },
    UserService.hasBackofficeRole() && {
      label: 'Bookings',
      icon: 'pi pi-fw pi-ticket',
      items: [
        { label: 'Bookings', icon: 'pi pi-fw pi-ticket', to: '/bookings' },
        { label: 'Participants', icon: 'pi pi-fw pi-user-plus', to: '/participants' },
        { label: 'Voucher', icon: 'pi pi-fw pi-money-bill', to: '/vouchers' },
        { label: 'Offloading', icon: 'pi pi-fw pi-undo', to: '/backpack' },
        { label: 'Participant Vitals', icon: 'pi pi-fw pi-user-plus', to: '/participantsVitals' },
        { label: 'Fitness Approval', icon: 'pi pi-fw pi-thumbs-up', to: '/fitness-approval' },
        { label: 'Reviews Dashboard', icon: 'pi pi-fw pi-eye', to: '/reviews-dasbhboard' },
      ],
    },
    UserService.hasBackofficeRole() && {
      label: 'Trek-Management',
      icon: 'pi pi-fw pi-compass',
      items: [
        { label: 'Treks', icon: 'pi pi-fw pi-compass', to: '/trek' },
        { label: 'Seasons', icon: 'pi pi-fw pi-cloud', to: '/season' },
        { label: 'Groups', icon: 'pi pi-fw pi-users', to: '/batches' },
        { label: 'Reviews', icon: 'pi pi-fw pi-eye', to: '/reviews' },
        { label: 'Special Trek Types', icon: 'pi pi-fw pi-compass', to: '/special-trek-types' },
      ],
    },
    UserService.hasBackofficeRole() && {
      label: 'User-Management',
      icon: 'pi pi-fw pi-id-card',
      items: [
        { label: 'Users', icon: 'pi pi-fw pi-id-card', to: '/users' },
        { label: 'Flags', icon: 'pi pi-fw pi-flag', to: '/user-flags' },
      ],
    },
    UserService.hasBackofficeRole() && {
      label: 'Miscellaneous',
      icon: 'pi pi-fw pi-map-marker',
      items: [
        { label: 'Promoters', icon: 'pi pi-fw pi-check-circle', to: '/promoters' },
        { label: 'Location', icon: 'pi pi-fw pi-map-marker', to: '/location' },
      ],
    },
    UserService.hasBackofficeRole() && {
      label: 'Website Management',
      icon: 'pi pi-fw pi-cog',
      items: [{ label: 'Content Mapping', icon: 'pi pi-fw pi-cog', to: '/website-content-mapping' }],
    },
  ];

  let routers;
  if (UserService.hasBackofficeRole()) {
    routers = [
      {
        path: '/location',
        exact: true,
        component: LocationList,
        meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Location' }] },
      },
      {
        path: '/location/add',
        exact: true,
        component: LocationDetail,
        meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Location' }] },
      },
      {
        path: '/location/edit/:id',
        exact: true,
        component: LocationDetail,
        meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Location' }] },
      },
      {
        path: '/tags',
        exact: true,
        component: TagsList,
        meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Tags' }] },
      },
      {
        path: '/awards',
        exact: true,
        component: AwardsList,
        meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Awards' }] },
      },
      {
        path: '/voucher-types',
        exact: true,
        component: VoucherTypesLists,
        meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Voucher Types' }] },
      },
      {
        path: '/promoters',
        exact: true,
        component: PromotersList,
        meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Promoters' }] },
      },
      {
        path: '/promoters/:id',
        exact: true,
        component: PromoterDetail,
        meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Promoters' }] },
      },
      {
        path: '/templates',
        exact: true,
        component: TemplatesList,
        meta: { breadcrumb: [{ parent: 'UI Kit', label: 'Templates' }] },
      },
      {
        path: '/trek',
        exact: true,
        component: TrekList,
        meta: { breadcrumb: [{ parent: 'Treks', label: 'Treks' }] },
      },
      {
        path: '/special-trek-types',
        exact: true,
        component: SpecialTrekTypeList,
        meta: { breadcrumb: [{ parent: 'Treks', label: 'Treks' }] },
      },
      {
        path: '/special-trek-types/add',
        exact: true,
        component: SpecialTrekTypeDetail,
        meta: { breadcrumb: [{ parent: 'Special Trek Types', label: 'Special Trek Types' }] },
      },
      {
        path: '/trek/add',
        exact: true,
        component: TrekDetail,
        meta: { breadcrumb: [{ parent: 'Treks', label: 'Treks' }] },
      },
      {
        path: '/trek/edit/:id',
        exact: true,
        component: TrekDetail,
        meta: { breadcrumb: [{ parent: 'Treks', label: 'Treks' }] },
      },
      {
        path: '/season',
        exact: true,
        component: SeasonList,
        meta: { breadcrumb: [{ parent: 'Seasons', label: 'Season' }] },
      },
      {
        path: '/season/add',
        exact: true,
        component: SeasonDetail,
        meta: { breadcrumb: [{ parent: 'Seasons', label: 'Season' }] },
      },
      {
        path: '/season/edit/:id',
        exact: true,
        component: SeasonDetail,
        meta: { breadcrumb: [{ parent: 'Seasons', label: 'Season' }] },
      },
      {
        path: '/batches',
        exact: true,
        component: BatchList,
        meta: { breadcrumb: [{ parent: 'Batches', label: 'Batches' }] },
      },
      {
        path: '/batches/add',
        exact: true,
        component: BatchDetail,
        meta: { breadcrumb: [{ parent: 'Batches', label: 'Batches' }] },
      },
      {
        path: '/batches/multi-batch',
        exact: true,
        component: MultiBatch,
        meta: { breadcrumb: [{ parent: 'Multi Batch', label: 'MultiBatch' }] },
      },
      {
        path: '/batches/edit/:id',
        exact: true,
        component: BatchDetail,
        meta: { breadcrumb: [{ parent: 'Batches', label: 'Batches' }] },
      },
      {
        path: '/batches/view/:id',
        exact: true,
        component: BatchActionViewComponent,
        meta: { breadcrumb: [{ parent: 'Batches', label: 'Batches' }] },
      },
      {
        path: '/batches/cancel/:id',
        exact: true,
        component: BatchCancellation,
        meta: { breadcrumb: [{ parent: 'Batches', label: 'Batches' }] },
      },
      {
        path: '/batches/transfer/:id',
        exact: true,
        component: BatchTransferDetail,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Transactions' }] },
      },
      {
        path: '/reviews',
        exact: true,
        component: ReviewList,
        meta: { breadcrumb: [{ parent: 'Reviews', label: 'Reviews' }] },
      },
      {
        path: '/reviews/add',
        exact: true,
        component: ReviewDetail,
        meta: { breadcrumb: [{ parent: 'Reviews', label: 'Reviews' }] },
      },
      {
        path: '/reviews/edit/:id',
        exact: true,
        component: ReviewDetail,
        meta: { breadcrumb: [{ parent: 'Reviews', label: 'Reviews' }] },
      },
      {
        path: '/reviews/preview/:id',
        exact: true,
        component: ReviewPreview,
        meta: { breadcrumb: [{ parent: 'Reviews', label: 'Reviews' }] },
      },
      {
        path: '/bookings',
        exact: true,
        component: BookingList,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Bookings' }] },
      },
      {
        path: '/bookings/add',
        exact: true,
        component: BookingDetail,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Bookings Detail' }] },
      },
      {
        path: '/bookings/edit/:id',
        exact: true,
        component: BookingDetail,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Bookings Detail' }] },
      },
      {
        path: '/bookings/view/:id',
        exact: true,
        component: ParticipantList,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Participant List' }] },
      },
      {
        path: '/bookings/participant/view/:id/:participantId',
        exact: true,
        component: ParticipantDetail,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Partcipant View' }] },
      },
      {
        path: '/bookings/transactions/:id',
        exact: true,
        component: Transactions,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Transactions' }] },
      },
      {
        path: '/bookings/participant/refund/:id/:participantId',
        exact: true,
        component: RefundDetail,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Transactions' }] },
      },
      {
        path: '/bookings/participant/cancellation/:id/:participantId',
        exact: true,
        component: ParticipantCancellation,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Transactions' }] },
      },
      {
        path: '/bookings/transfer/:id',
        exact: true,
        component: TransferDetail,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Transactions' }] },
      },
      {
        path: '/Participants',
        exact: true,
        component: BookingParticipants,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Bookings' }] },
      },
      {
        path: '/users',
        exact: true,
        component: UserList,
        meta: {},
      },
      {
        path: '/users/:userId',
        exact: true,
        component: UserDetail,
        meta: {},
      },
      {
        path: '/user-flags',
        exact: true,
        component: UserFlags,
        meta: {},
      },
      {
        path: '/vouchers',
        exact: true,
        component: BookingVochers,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Bookings' }] },
      },
      {
        path: '/vouchers/newvoucher',
        exact: true,
        component: NewVoucher,
        meta: { breadcrumb: [{ parent: 'Vouchers', label: 'Vouchers' }] },
      },
      {
        path: '/vouchers/edit/:id',
        exact: true,
        component: NewVoucher,
        meta: { breadcrumb: [{ parent: 'Vouchers', label: 'Vouchers' }] },
      },
      {
        path: '/vouchers/bulkvoucher',
        exact: true,
        component: BulkVoucherComponent,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Bookings' }] },
      },
      {
        path: '/vouchers/vouchercode',
        exact: true,
        component: VoucherCode,
        meta: { breadcrumb: [{ parent: 'Vouchers', label: 'Vouchers' }] },
      },
      {
        path: '/vouchers/vouchercode/edit/:id',
        exact: true,
        component: VoucherCode,
        meta: { breadcrumb: [{ parent: 'Vouchers', label: 'Vouchers' }] },
      },
      {
        path: '/backpack',
        exact: true,
        component: BackPackOffloading,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Bookings' }] },
      },
      {
        path: '/backpack_details',
        exact: true,
        component: BackPackOffloadingDetails,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Bookings' }] },
      },
      {
        path: '/:batchId/approve_participants',
        exact: true,
        component: ApproveParticipants,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Bookings' }] },
      },
      {
        path: '/:batchId/backpack',
        exact: true,
        component: BackpackParticipants,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Bookings' }] },
      },
      {
        path: '/batch_review/:batchId',
        exact: true,
        component: BatchReviews,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Bookings' }] },
      },
      {
        path: '/batch_review/:batchId/userId/:userId/reviewId/:reviewId',
        exact: true,
        component: ReviewComments,
        meta: { breadcrumb: [{ parent: 'Batch', label: 'ReviewComments' }] },
      },
      {
        path: '/participantsVitals',
        exact: true,
        component: ParticpantsVitalsComponent,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Bookings' }] },
      },
      {
        path: '/participantsVitals/newvital',
        exact: true,
        component: NewVitalComponent,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Bookings' }] },
      },
      {
        path: '/point',
        exact: true,
        component: EmptyPage,
        meta: { breadcrumb: [{ parent: 'Empty', label: 'Empty' }] },
      },
      {
        path: '/bookings/participant/edit/:id/:participantId',
        exact: true,
        component: ParticipantEdit,
      },
      {
        path: '/:bookingId/participant/view/:participantId',
        exact: true,
        component: ParticipantView,
      },
      {
        path: '/website-content-mapping',
        exact: true,
        component: WebsiteContentMapping,
      },
      {
        path: '/dashboard',
        exact: true,
        component: Dashboard,
        meta: { breadcrumb: [{ parent: 'Dashboard', label: 'Dashboard' }] },
      },
      {
        path: '/fitness-approval',
        exact: true,
        component: FitnessApproval,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Fitness Approval' }] },
      },
      {
        path: '/reviews-dasbhboard',
        exact: true,
        component: ReviewDashboard,
        meta: { breadcrumb: [{ parent: 'Bookings', label: 'Reviews Dashboard' }] },
      },
      {
        path: '/',
        exact: true,
        component: BatchList,
        meta: { breadcrumb: [{ parent: 'Pages', label: 'Empty Page' }] },
      },
    ];
  } else {
    routers = [
      {
        path: '/',
        exact: true,
        component: Unauthorized,
        meta: { breadcrumb: [{ parent: 'Pages', label: 'Empty Page' }] },
      },
    ];
  }

  const onInputStyleChange = (inputStyle: any) => {
    setInputStyle(inputStyle);
  };

  const onRippleChange = (e: any) => {
    //PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onMenuModeChange = (e: any) => {
    setMenuMode(e.value);
    setStaticMenuDesktopInactive(false);
    setOverlayMenuActive(false);

    if (e.value === 'horizontal') {
      setProfileMode('popup');
    }
  };

  const onMenuColorChange = (e: any) => {
    setDarkMenu(e.value);
  };

  const onProfileChange = (e: any) => {
    setProfileMode(e.value);
  };

  const onDocumentClick = () => {
    if (!topbarItemClick) {
      setActiveTopbarItem(null);
      setTopbarMenuActive(false);
    }

    if (!menuClick) {
      if (isHorizontal() || isSlim()) {
        setMenuActive(false);
      }
      hideOverlayMenu();
    }

    if (!inlineMenuClick && profileMode === 'inline' && isSlim() && !isMobile()) {
      setInlineMenuActive(false);
    }

    if (configActive && !configClick) {
      setConfigActive(false);
    }

    inlineMenuClick = false;
    configClick = false;
    topbarItemClick = false;
    menuClick = false;
  };

  const onMenuitemClick = (event: any) => {
    // if (event.item.label === 'Users') {
    //   window.open(`${process.env.REACT_APP_IAM_URL}/auth/admin/IndiaHikes/console/#/realms/IndiaHikes/users`, '_blank');
    // } else {
    if (!event.item.items) {
      hideOverlayMenu();

      if (isSlim() || isHorizontal()) {
        setMenuActive(false);
      }
    }
    //}
  };

  const onRootMenuitemClick = () => {
    setMenuActive((prevMenuActive) => !prevMenuActive);
  };

  const onMenuClick = () => {
    menuClick = true;

    if (inlineMenuActive && !inlineMenuClick) {
      setInlineMenuActive(false);
    }
  };

  const isMenuVisible = () => {
    if (isDesktop()) {
      if (menuMode === 'static') return !staticMenuDesktopInactive;
      else if (menuMode === 'overlay') return overlayMenuActive;
      else return true;
    } else {
      return true;
    }
  };

  const onMenuButtonClick = (event: any) => {
    menuClick = true;
    setTopbarMenuActive(false);

    if (isOverlay() && !isMobile()) {
      setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
    } else {
      if (isDesktop()) {
        setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive);
      } else {
        setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
      }
    }

    event.preventDefault();
  };

  const onProfileButtonClick = (event: any) => {
    setInlineMenuActive((prevInlineMenuActive) => !prevInlineMenuActive);
    inlineMenuClick = true;

    if (isSlim() || isHorizontal()) {
      setMenuActive(false);
    }
  };

  const onTopbarMenuButtonClick = (event: any) => {
    topbarItemClick = true;
    setTopbarMenuActive((prevTopbarMenuActive) => !prevTopbarMenuActive);

    hideOverlayMenu();

    event.preventDefault();
  };

  const onTopbarItemClick = (event: any, item: any) => {
    topbarItemClick = true;

    if (activeTopbarItem === item) {
      setActiveTopbarItem(null);
    } else {
      setActiveTopbarItem(item);
    }

    event.preventDefault();
  };

  const onConfigClick = () => {
    configClick = true;
  };

  const onConfigButtonClick = () => {
    setConfigActive((prevConfigActive) => !prevConfigActive);
    configClick = true;
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
  };

  const isDesktop = () => {
    return window.innerWidth > 896;
  };

  const isMobile = () => {
    return window.innerWidth <= 896;
  };

  const isOverlay = () => {
    return menuMode === 'overlay';
  };

  const isHorizontal = () => {
    return menuMode === 'horizontal';
  };

  const isSlim = () => {
    return menuMode === 'slim';
  };

  const isStatic = () => {
    return menuMode === 'static';
  };

  const hasInlineProfile = profileMode === 'inline' && !isHorizontal();

  const containerClassName = classNames('layout-wrapper', {
    'layout-static': isStatic(),
    'layout-overlay': isOverlay(),
    'layout-overlay-active': overlayMenuActive,
    'layout-horizontal': isHorizontal(),
    'layout-slim': isSlim(),
    'layout-static-inactive': staticMenuDesktopInactive,
    'layout-mobile-active': staticMenuMobileActive,
    'layout-menu-dark': darkMenu,
    'layout-menu-light': !darkMenu,
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': !ripple,
  });

  const menuContainerClassName = classNames('layout-menu-container', {
    'layout-menu-container-inactive': !isMenuVisible(),
  });

  return (
    <div className={containerClassName} onClick={onDocumentClick}>
      <AppTopbar
        topbarMenuActive={topbarMenuActive}
        activeTopbarItem={activeTopbarItem}
        onMenuButtonClick={onMenuButtonClick}
        onTopbarMenuButtonClick={onTopbarMenuButtonClick}
        onTopbarItemClick={onTopbarItemClick}
        isHorizontal={isHorizontal()}
        profileMode={profileMode}
        isMobile={isMobile}
      />

      <div className={menuContainerClassName} onClick={onMenuClick}>
        <div className="layout-menu-logo">
          <button className="p-link">
            <img
              id="layout-menu-logo"
              src="assets/layout/images/logo.png"
              library="babylon-layout"
              alt="babylon-logo"
            />
          </button>
        </div>
        <div className="layout-menu-wrapper">
          <div className="menu-scroll-content">
            {hasInlineProfile && (
              <AppInlineProfile inlineMenuActive={inlineMenuActive} onProfileButtonClick={onProfileButtonClick} />
            )}
            <AppMenu
              model={menu}
              menuMode={menuMode}
              active={menuActive}
              onMenuitemClick={onMenuitemClick}
              onRootMenuitemClick={onRootMenuitemClick}
            />
          </div>
        </div>
      </div>

      <div className="layout-main">
        <div className="layout-content">
          <Suspense fallback={<div>Page is Loading...</div>}>
            <Switch>
              {routers.map((router: any, index) => {
                if (router.exact) {
                  return <Route key={`router${index}`} path={router.path} exact component={router.component} />;
                }
                return <Route key={`router${index}`} path={router.path} component={router.component} />;
              })}
            </Switch>
          </Suspense>
        </div>
        <AppFooter />
      </div>

      {/* <AppConfig configActive={configActive} menuMode={menuMode} onMenuModeChange={onMenuModeChange}
                isDarkMenu={darkMenu} onMenuColorChange={onMenuColorChange}
                profileMode={profileMode} onProfileChange={onProfileChange} onConfigClick={onConfigClick} onConfigButtonClick={onConfigButtonClick}
                rippleActive={ripple} onRippleChange={onRippleChange} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} /> */}

      {staticMenuMobileActive && <div className="layout-mask"></div>}
    </div>
  );
};

export default App;
