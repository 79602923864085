import axios from 'axios';
import { pickBy } from 'lodash';

import { VoucherCodeModel } from '../models/Voucher/VoucherCodeModel';
import { IVoucherCodeFilter } from '../models/Voucher/VoucherFilters';
import { VoucherList } from '../models/Voucher/Voucher';
import { getHeaderWithBearerToken } from '../helpers';

export const voucherCodeBaseApi = `${process.env.REACT_APP_TMS_BACKEND_URL}/voucher/codes`;

class VoucherCodeService {
  get = (
    pageNo: number,
    pageSize: number,
    voucherCodeFilter?: IVoucherCodeFilter,
    sortField?: string,
    sortOrderDescending?: boolean,
  ) => {
    return axios.get<VoucherList<VoucherCodeModel>>(voucherCodeBaseApi, {
      headers: getHeaderWithBearerToken(),
      params: {
        pageNo,
        pageSize,
        sortField,
        sortOrderDescending,
        ...pickBy(voucherCodeFilter, (val) => {
          return val !== undefined;
        }),
      },
    });
  };

  getById = (trekId: number) => {
    const url = `${voucherCodeBaseApi}/${trekId}`;
    return axios.get<VoucherCodeModel>(url, {
      headers: getHeaderWithBearerToken(),
    });
  };

  update = (data: VoucherCodeModel) => {
    const url = `${voucherCodeBaseApi}`;
    return axios.put(url, data, {
      headers: { ...getHeaderWithBearerToken() },
    });
  };

  create = (data: VoucherCodeModel) => {
    const url = `${voucherCodeBaseApi}`;
    return axios.post<VoucherCodeModel>(url, data, { headers: getHeaderWithBearerToken() });
  };

  updateStatus = (id: number, status: string) => {
    const url = `${voucherCodeBaseApi}/${id}/status?voucherCodeStatus=${status}`;
    return axios.put<VoucherCodeModel>(url, {}, {
      headers: getHeaderWithBearerToken(),
    });
  }
}

const voucherCodeApi = new VoucherCodeService();
export default voucherCodeApi;
